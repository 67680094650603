import React from 'react'
import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMapEvents } from 'react-leaflet'

const MapSelector = () => {
  return(
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="国土地理院 標準">
          <TileLayer
            attribution="<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
            url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="国土地理院 淡色">
          <TileLayer
            attribution="<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
            url='https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="国土地理院 写真">
          <TileLayer
            attribution="<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
            url='https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenStreetMap 白黒">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        {/*
        <LayersControl.BaseLayer name='toner-background'>
          <TileLayer
            attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>'
            url='http://{s}.tile.stamen.com/{variant}/{z}/{x}/{y}.png'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='toner-lite'>
          <TileLayer
            attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>'
            url='http://{s}.tile.stamen.com/{variant}/{z}/{x}/{y}.png'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='watercolor'>
          <TileLayer
            attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>'
            url='http://{s}.tile.stamen.com/{variant}/{z}/{x}/{y}.png'
          />
        </LayersControl.BaseLayer>
        */}
        <LayersControl.BaseLayer name='ESRI World Topo Map'>
          <TileLayer
            attribution='Tiles &copy; <a href="http://www.esrij.com/"> Esri Japan </a>'
            url='http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='Thunderforest Outdoors'>
          <TileLayer
            attribution='&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=8d7ebe86d08b4b8eab4e24124d3f0505'
          />
        </LayersControl.BaseLayer>
        {/* leafletで使えるマップ https://leaflet-extras.github.io/leaflet-providers/preview/ */}
      </LayersControl>
  )
}

export default MapSelector;