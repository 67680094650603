import React, { useState, useContext } from 'react'
import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMapEvents } from 'react-leaflet'
import { makeStyles } from '@mui/styles'
import MapSelector from './MapSelector';
import { PageContext } from "../providers/PageProvider";

const useStyles = makeStyles(() =>  ({
    container: {
      height: '100%',
      "z-index": -1,
    }
}));

const locateOptions = {
  position: 'topright',
  strings: {
      title: 'Show me where I am, yo!'
  },
  onActivate: () => {} // callback before engine starts retrieving locations
}

function LocationMarker() {
  const [position, setPosition] = useState(null)
  const map = useMapEvents({
    click() {
      map.locate()
    },
    locationfound(e) {
      setPosition(e.latlng)
      map.flyTo(e.latlng, map.getZoom())
    },
  })

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  )
}

const MapBox = () => {
  const position = [35.5304313,139.7216911];
  const mapStyle = { height: 600 };
  const currentPage = useContext(PageContext);
  console.log(currentPage);

  return (
    <MapContainer
      center={position} zoom={16}
      scrollWheelZoom={true}
      style={mapStyle} >

      <MapSelector />
      <Marker position={position}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
      <LocationMarker />
    </MapContainer>
  )
}

export default MapBox;