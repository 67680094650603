import React, {useState, useEffect} from "react";

export const PageContext = React.createContext(["home", () => {}]);

export const PageProvider = ({children}) => {
	const [currentPage, setCurrentPage] = useState("home");

	useEffect(() => {
		console.log("useEffect called.");
		console.log(currentPage);
	}, []); /* レンダリング時に１回のみ */

	return (
		<PageContext.Provider value={[currentPage, setCurrentPage]}>
			{children}
		</PageContext.Provider>
	);
};

export default PageProvider;
