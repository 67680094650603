import React, {useContext} from 'react';
import Leaflet from 'leaflet'
import MapBox from './MapBox';
import TimelineBox from './TimelineBox';
import EditorPage from './EditorPage';
import FeedPage from './FeedPage';
import Buttonbar from './Buttonbar';
import {PageContext} from '../providers/PageProvider';

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/'

const Page = () => {

  const [currentPage, setCurrentPage] = useContext(PageContext);

  const pageRender = () => {
    let dom;
    console.log(currentPage);
    if (currentPage === "home") {
      dom = <MapBox />

    } else if (currentPage === "timeline") {
      dom = <TimelineBox />

    } else if (currentPage === "feed") {
      dom = <FeedPage />

    } else if (currentPage === "edit") {
      dom = 
        <div id="container">
          <h2>Editor</h2>
          <EditorPage />
        </div>

    } else {
      dom = <h2>Account Page</h2>
    }
    return dom;
  }

  return (
    pageRender()
  );
}

export default Page;