import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { PageContext } from "../providers/PageProvider";
import axios from "axios";

const useStyles = makeStyles(() =>  ({
    container: {
      height: '100%',
      "z-index": -1,
    }
}));

export const FeedPage = () => {
  const [data, setData] = useState(null);

  useEffect(async () => {
    const result = await axios(
      'https://kavote-dev.web.app/api/hogehoge'
    );
    setData(result.data);
  });

  return (
    <div>
      <h2> Feed Page by http </h2>
      <div>
        {data}
      </div>
    </div>
  )
}

export default FeedPage;