import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { PageContext } from "../providers/PageProvider";
//import {Editor, EditorState} from 'draft-js';
import { Editor } from '@tinymce/tinymce-react';
import 'draft-js/dist/Draft.css';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>  ({
    container: {
      height: '100%',
      "z-index": -1,
    }
}));



const EditorPage = () => {
  const classes = useStyles();
  const currentPage = useContext(PageContext);

  //const [editorState, setEditorState] = React.useState(
  //  () => EditorState.createEmpty()
  //);


  /*
  const handleTogggleClick = (e: React.MouseEvent, inlineStyle: string) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };
  const handleBlockClick = (e: React.MouseEvent, blockType: string) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };
  */

  //const [value, setValue] = useState('');
  //const onChange = (evt) => setValue(evt.target.value);


  /*
  return (
    <div>
      <input value={value} onChange={onChange} />;
      <Editor className={classes.nav} editorState={editorState} onChange={setEditorState} />;
    </div>
  )
  */

  const updateHandle = (e) => {
    console.log('Content was updated:', e.target.getContent());
  }

  return (
    <Editor
      initialValue="<p>This is the initial content of the editor</p>"
      apiKey='dyfaxkwvm22h7sa5wwvizymr45pvi86e55o9zdbtlrc7jxfj'
      init={{
        mobile: {
          theme: 'mobile',
          plugins: [ 'autosave', 'lists', 'autolink' ]
        },
        plugins: 'link image code',
        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
      }}
      onChcange={(e) => updateHandle(e)}
    />
  )

}

//ReactDOM.render(<MyEditor />, document.getElementById('container'));

export default EditorPage;