import React, {useContext} from 'react';
import Leaflet from 'leaflet'
import './App.css';
import 'leaflet/dist/leaflet.css';
import MapBox from './components/MapBox';
import TimelineBox from './components/TimelineBox';
import Buttonbar from './components/Buttonbar';
import Page from './components/Page';
import PageProvider, {PageContext} from './providers/PageProvider';

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/'

function App() {

  const [currentPage, setCurrentPage] = useContext(PageContext);

  return (
    <PageProvider>
      <h1>Map and Timeline</h1>
      <Page />
      <Buttonbar />
    </PageProvider>
  );
}

export default App;