import React, {useState, useContext} from "react";
//import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { makeStyles } from '@mui/styles';
//import RestoreIcon from "@mui/icons-material/Restore";
//import FavoriteIcon from "@mui/icons-material/Favorite";
//import LocationOnIcon from "@mui/icons-material/LocationOn";
//import DeleteIcon from '@mui/icons-material/Delete';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListIcon from '@mui/icons-material/List';
//import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { PageContext } from "../providers/PageProvider";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        height: 56,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFF",
        backgroundColor: "#3f51b5",
        position: "fixed",
        bottom: 0,
    },
    nav: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        bottom: 0,
    }
}));

const Buttonbar = () => {
    const classes = useStyles();
    //const [page, setPage] = useState('home');
    const [currentPage, setCurrentPage] = useContext(PageContext);

    console.log(currentPage);
    //console.log(page);
    return(
        <BottomNavigation
          className={classes.nav}
          showLabels
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} onClick={() => setCurrentPage("home")} />
          <BottomNavigationAction label="Timeline" icon={<ScheduleIcon />} onClick={() => setCurrentPage("timeline")} />
          <BottomNavigationAction label="Feed" icon={<ListIcon />} onClick={() => setCurrentPage("feed")} />
          <BottomNavigationAction label="Account" icon={<AccountCircleIcon />} onClick={() => setCurrentPage("account")} />
          <BottomNavigationAction label="Editor" icon={<ModeEditOutlineIcon />} onClick={() => setCurrentPage("edit")} />
        </BottomNavigation>
    )
}

export default Buttonbar;
